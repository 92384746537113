import React, { useState, useEffect, useCallback } from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { withTranslation } from 'react-i18next'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import api from '../services/api'
import { useAlert } from '../services/alertService'
import { formatarData, formatarHorario } from '../utils/dateService'
import { useAuth } from '../hooks/auth'
import { Atendimento } from '../dtos/Dtos'

type NotificacaoDoUsuario = {
  lida: boolean
  notificacao: Notificacao
}

type Notificacao = {
  _id: string
  titulo: string
  descricao: string
  dataPublicacao: Date
}

const TheHeaderNotificacoes: React.FC = () => {
  const { handleResponseMessages } = useAlert()
  const { user } = useAuth()
  const history = useHistory()

  const [notificacoesDoUsuario, setNotificacoesDoUsuario] = useState(
    [] as NotificacaoDoUsuario[]
  )
  const [qtdeNotificacoes, setQtdeNotificacoes] = useState(Number)
  const [companies, setCompanies] = useState([] as Atendimento[])
  useEffect(() => {
    const carregarNotificacoesDoUsuario = async (): Promise<void> => {
      try {
        const response = await api.get('/notificacoesDoUsuario')
        const notificacoesResp: NotificacaoDoUsuario[] =
          response.data.data.notificacoes
        setQtdeNotificacoes(
          notificacoesResp.reduce(
            (prev, notificacaoDoUsuario) =>
              !notificacaoDoUsuario.lida ? prev + 1 : prev,
            0
          )
        )
        setNotificacoesDoUsuario(notificacoesResp)
      } catch (error: any) {
        handleResponseMessages(error)
      }
    }
    const carregarEmpresas = async (): Promise<void> => {
      try {
        const response = await api.post(
          'listagemAtendimento/listarAtendimentosDoUsuario',
          {
            filtro: {
              dataDisponibilidade: '2000-01-01'
            }
          }
        )
        const arrayFiltrado = response.data.data.atendimentos.filter(
          (obj, index, self) =>
            index === self.findIndex(o => o.clienteId === obj.clienteId)
        )
        setCompanies(arrayFiltrado)
      } catch (error: any) {
        handleResponseMessages(error)
      }
    }

    carregarEmpresas()
    carregarNotificacoesDoUsuario()
  }, [handleResponseMessages])

  const [notificacaoSelecionada, setNotificacaoSelecionada] = useState(
    {} as NotificacaoDoUsuario
  )
  const [showDialogNotificacao, setShowDialogNotificacao] = useState(false)

  const handleClose = (): void => setShowDialogNotificacao(false)
  const handleShow = (): void => setShowDialogNotificacao(true)

  const handleNotificacaoDoUsuario = async (
    notificacaoDoUsuario: NotificacaoDoUsuario
  ): Promise<void> => {
    setNotificacaoSelecionada(notificacaoDoUsuario)
    handleShow()
    if (!notificacaoDoUsuario.lida) {
      const response = await api.get(
        // eslint-disable-next-line no-underscore-dangle
        `/notificacoesDoUsuario/marcarComoLida/${notificacaoDoUsuario.notificacao._id}`
      )
      setQtdeNotificacoes(qtdeNotificacoes - 1)
      atualizarListaDeNotificacoes(response.data.data.notificacaoDoUsuario)
    }
  }

  const atualizarListaDeNotificacoes = useCallback(
    (notificacaoLida: NotificacaoDoUsuario): void => {
      const novoArray = [...notificacoesDoUsuario]
      const elementIndex = notificacoesDoUsuario.findIndex(
        (pro: NotificacaoDoUsuario) =>
          // eslint-disable-next-line no-underscore-dangle
          pro.notificacao._id === notificacaoLida.notificacao._id
      )
      novoArray[elementIndex] = notificacaoLida
      setNotificacoesDoUsuario(novoArray)
    },
    [notificacoesDoUsuario]
  )

  return (
    <>
      {user.tipoUsuario === 'Profissional' && (
        <CDropdown inNav className="c-header-nav-item mx-2">
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            <CIcon name="cil-building" />
            {qtdeNotificacoes > 0 && (
              <CBadge shape="pill" color="info">
                {qtdeNotificacoes}
              </CBadge>
            )}
          </CDropdownToggle>
          <CDropdownMenu
            className="pt-0 menu-notificacoes dropdown-notificacao"
            placement="bottom-end"
          >
            <CDropdownItem header tag="div" color="light">
              <strong>Escolha qual cliente deseja visualizar</strong>
            </CDropdownItem>
            <CDropdownItem
              onClick={() => {
                sessionStorage.removeItem('currentCompany')
                window.location.reload()
              }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <CIcon style={{ marginRight: '10px' }} name="cil-people" />
              <>Todos</>
            </CDropdownItem>
            {companies &&
              companies.map(company => (
                <CDropdownItem
                  style={{ display: 'flex', alignItems: 'center' }}
                  href="#"
                  onClick={() => {
                    console.log(user)
                    sessionStorage.setItem(
                      'currentCompany',
                      JSON.stringify({
                        clienteId: company.clienteId,
                        cliente: company.cliente
                      })
                    )
                    window.location.reload()
                  }}
                >
                  <CIcon style={{ marginRight: '10px' }} name="cil-user" />
                  <div>{company.empresa}</div>
                  {/* <div className="message">
                      <div className="pt-1 mr-2 float-left">
                        <div className="nova-notificacao">
                          {!notificacaoDoUsuario.lida && (
                            <span className="status-notificacao bg-success" />
                          )}
                        </div>
                      </div>
                      <div className="text-truncate font-weight-bold">
                        <div className="titulo-notificacao text-truncate font-weight-bold float-left">
                          {notificacaoDoUsuario.notificacao.titulo}
                        </div>
                        <small className="text-muted float-right mt-1">
                          {`${formatarData(
                            user,
                            notificacaoDoUsuario.notificacao.dataPublicacao,
                          )} ${formatarHorario(
                            user,
                            notificacaoDoUsuario.notificacao.dataPublicacao,
                          )}`}
                        </small>
                      </div>
                      {notificacaoDoUsuario.notificacao.descricao && (
                        <div className="small text-muted text-truncate">
                          {notificacaoDoUsuario.notificacao.descricao}
                        </div>
                      )}
                    </div> */}
                </CDropdownItem>
              ))}
          </CDropdownMenu>
        </CDropdown>
      )}

      <CDropdown inNav className="c-header-nav-item mx-2">
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <CIcon name="cil-bell" />
          {qtdeNotificacoes > 0 && (
            <CBadge shape="pill" color="info">
              {qtdeNotificacoes}
            </CBadge>
          )}
        </CDropdownToggle>
        <CDropdownMenu
          className="pt-0 menu-notificacoes dropdown-notificacao"
          placement="bottom-end"
        >
          <CDropdownItem header tag="div" color="light">
            {qtdeNotificacoes > 0 && (
              <strong>
                Você tem {qtdeNotificacoes}
                {qtdeNotificacoes > 1 ? ' novas mensagens ' : ' nova mensagem'}
              </strong>
            )}
            {qtdeNotificacoes === 0 && (
              <strong>Você não tem novas mensagens</strong>
            )}
          </CDropdownItem>
          {notificacoesDoUsuario &&
            notificacoesDoUsuario.map(notificacaoDoUsuario => (
              <CDropdownItem
                href="#"
                onClick={() => handleNotificacaoDoUsuario(notificacaoDoUsuario)}
              >
                <div className="message">
                  <div className="pt-1 mr-2 float-left">
                    <div className="nova-notificacao">
                      {!notificacaoDoUsuario.lida && (
                        <span className="status-notificacao bg-success" />
                      )}
                    </div>
                  </div>
                  <div className="text-truncate font-weight-bold">
                    <div className="titulo-notificacao text-truncate font-weight-bold float-left">
                      {notificacaoDoUsuario.notificacao.titulo}
                    </div>
                    <small className="text-muted float-right mt-1">
                      {`${formatarData(
                        user,
                        notificacaoDoUsuario.notificacao.dataPublicacao
                      )} ${formatarHorario(
                        user,
                        notificacaoDoUsuario.notificacao.dataPublicacao
                      )}`}
                    </small>
                  </div>
                  {notificacaoDoUsuario.notificacao.descricao && (
                    <div className="small text-muted text-truncate">
                      {notificacaoDoUsuario.notificacao.descricao}
                    </div>
                  )}
                </div>
              </CDropdownItem>
            ))}
        </CDropdownMenu>
      </CDropdown>

      <CDropdown
        inNav
        className="c-header-nav-item mx-2"
        onClick={() => {
          history.push('/Faq/list')
        }}
      >
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <CIcon name="cil-task" />
          <CBadge shape="pill" color="info" />
        </CDropdownToggle>
      </CDropdown>

      <Modal
        show={showDialogNotificacao}
        backdrop="static"
        size="lg"
        onHide={handleClose}
      >
        {notificacaoSelecionada && notificacaoSelecionada.notificacao && (
          <>
            <Modal.Header className="bg-instituicao">
              <Modal.Title>
                {notificacaoSelecionada.notificacao.titulo}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="pre-wrap">
                {notificacaoSelecionada.notificacao.descricao}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                OK
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  )
}

export default withTranslation('translations')(TheHeaderNotificacoes)
