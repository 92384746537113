import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translations_pt_br from './pt-br'
import translations_en_us from './en-us'
import { InstituicaoHelper } from '../utils/InstituicaoHelper'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // we init with resources
    resources: {
      pt_br: {
        translations: translations_pt_br
      },
      en_us: {
        translations: translations_en_us
      }
    },
    fallbackLng: InstituicaoHelper.isBinMinas() ? 'en_us' : 'pt_br',
    debug: true,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })
export default i18n
