import { freeSet } from '@coreui/icons'
import { sygnet } from './sygnet.js'
import { logo } from './logo.js'
import { logoNegative } from './logo-negative.js'

export const icons = {
  ...freeSet,
  sygnet,
  logo,
  logoNegative
}
