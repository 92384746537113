import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import {
  format as dateFnsFormat,
  endOfDay,
  startOfDay,
  startOfWeek,
  endOfWeek,
  isBefore,
  addMinutes,
  addHours,
  parse,
  differenceInMinutes,
  subDays,
  isWeekend,
  isSameDay,
  isSameMonth,
  isSameYear
} from 'date-fns'
import { ptBR as ptBrDateFns, enUS as enUsDateFns } from 'date-fns/locale'
import i18n from '../i18n/i18n.js'

const i18nextToDateFnsLocale = (i18nLanguage: string): Locale => {
  if (i18nLanguage === 'pt-br') {
    return ptBrDateFns
  }
  if (i18nLanguage === 'en-us') {
    return enUsDateFns
  }
  return ptBrDateFns
}

export type TimeZoneDate = Date & {
  timezone: string
}

const applyTimezone = (
  data: Date,
  user: { timezone: string }
): TimeZoneDate => {
  if ((data as TimeZoneDate).timezone) {
    return data as TimeZoneDate
  }

  const timeZoneDate: TimeZoneDate = utcToZonedTime(
    data.toString(),
    user.timezone
  ) as TimeZoneDate
  timeZoneDate.timezone = user.timezone
  return timeZoneDate
}

const removeTimeZone = (data: Date, user: { timezone: string }): Date => {
  return zonedTimeToUtc(data, user.timezone)
}

const dateFormat = (
  date: Date | number,
  format: string,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string => {
  const locale = i18nextToDateFnsLocale('pt-br')

  return dateFnsFormat(date, format, {
    ...options,
    locale
  })
}

const formatarHorario = (
  user: { timezone: string },
  date: Date,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string => {
  return dateFormat(applyTimezone(date, user), 'HH:mm', options)
}

const formatarHorarioTmz = (
  date: Date,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string => {
  return dateFormat(date, 'HH:mm', options)
}
const formatarData = (
  user: { timezone: string },
  date: Date,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string => {
  return dateFormat(applyTimezone(date, user), i18n.t('format.date'), options)
}

const formatarDataTmz = (
  date: Date,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string => {
  return dateFormat(date, i18n.t('format.date'), options)
}

const formatarDataPorExtenso = (
  user: { timezone: string },
  date: Date,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string => {
  return dateFormat(
    applyTimezone(date, user),
    i18n.t('format.longDate'),
    options
  )
}

const formatarDataPorExtensoTmz = (
  date: Date,
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string => {
  return dateFormat(date, i18n.t('format.longDate'), options)
}

const primeiroHorarioDaData = (data: Date): Date => {
  return startOfDay(data)
}

const ultimoHorarioDaData = (data: Date): Date => {
  return endOfDay(data)
}

const primeiraDataDaSemana = (data: Date): Date => {
  return startOfWeek(data)
}

const ultimaDataDaSemana = (data: Date): Date => {
  return endOfWeek(data)
}

const verificaAnterior = (data1: Date, data2: Date): boolean => {
  return isBefore(data1, data2)
}

const adicionaMinutos = (data: Date, minutos: number): Date => {
  return addMinutes(data, minutos)
}

const adicionaHoras = (data: Date, horas: number): Date => {
  return addHours(data, horas)
}

const subtraiDias = (data: Date, dias: number): Date => {
  return subDays(data, dias)
}

const converterHoraMinutoParaData = (horaEMinuto: string, data: Date): Date => {
  return parse(horaEMinuto, 'HH:mm', data)
}

const diferencaEmMinutos = (dataInicio: Date, dataFim: Date): number => {
  return differenceInMinutes(dataFim, dataInicio)
}

const ehFimDeSemana = (data: Date): boolean => {
  return isWeekend(data)
}

const ehMesmaData = (data1: Date, data2: Date): boolean => {
  return (
    isSameDay(data1, data2) &&
    isSameMonth(data1, data2) &&
    isSameYear(data1, data2)
  )
}

export {
  applyTimezone,
  removeTimeZone,
  dateFormat,
  formatarHorario,
  formatarHorarioTmz,
  formatarData,
  formatarDataTmz,
  formatarDataPorExtenso,
  formatarDataPorExtensoTmz,
  i18nextToDateFnsLocale,
  primeiroHorarioDaData,
  ultimoHorarioDaData,
  primeiraDataDaSemana,
  ultimaDataDaSemana,
  verificaAnterior,
  adicionaMinutos,
  adicionaHoras,
  converterHoraMinutoParaData,
  diferencaEmMinutos,
  subtraiDias,
  ehFimDeSemana,
  ehMesmaData
}
