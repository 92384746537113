import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// import Route from '../routes/Route';

import { CContainer, CFade } from '@coreui/react'
import { useAuth } from '../hooks/auth'

// routes config
import routes from '../alternativeRoutes.js'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

const TheContent = () => {
  const { user, usuarioLogadoContemPermissaoAdministrador, lembreDeMim } =
    useAuth()
  const ehRotaPrivadaOuComUsuarioLogado = route => {
    if (
      route.isPrivate &&
      user &&
      user.comSenhaAutomatica &&
      route.path !== '/alterarSenha'
    ) {
      return false
    }

    if (route.name === 'Login' && lembreDeMim) {
      return false
    }

    return route.isPrivate === !!user
  }

  const redirect = route => {
    if (route.isPrivate) {
      return {
        pathname: '/',
        state: { from: route.path }
      }
    }

    let paginaInicial =
      user.tipoUsuario === 'Cliente'
        ? '/profissional/buscar'
        : '/atendimento/listar'

    if (usuarioLogadoContemPermissaoAdministrador()) {
      paginaInicial = '/profissionais/manter/listar'
    }

    if (user.comSenhaAutomatica) {
      paginaInicial = '/alterarSenha'
    }

    if (
      !user.comSenhaAutomatica &&
      user.perfilIncompleto &&
      user.tipoUsuario === 'Profissional'
    ) {
      paginaInicial = `/profissional/editar/${user.idProfissional}`
    }

    return {
      pathname: paginaInicial,
      state: { from: route.path }
    }
  }

  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <CFade>
                        {ehRotaPrivadaOuComUsuarioLogado(route) ? (
                          <route.component {...props} />
                        ) : (
                          <Redirect to={redirect(route)} />
                        )}
                      </CFade>
                    )}
                  />
                )
              )
            })}
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default React.memo(TheContent)
