/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import i18n from '../i18n/i18n.js'
import { InstituicaoHelper } from './InstituicaoHelper'

type MenuItem = {
  label: string
  icon: string
  path: string
  group: string
  order: number
}

const menuItemPorFuncionalidade = {
  MANTER_NOTIFICACAO: {
    label: i18n.t('label.notificacoes'),
    icon: 'cil-bell',
    path: '/notificacoes',
    group: i18n.t('grupo.administracao')
  } as MenuItem,
  MANTER_PROFISSIONAL: {
    label: i18n.t('label.profissionais'),
    icon: 'cil-group',
    path: '/profissionais/manter/listar',
    group: i18n.t('grupo.administracao')
  } as MenuItem,
  MANTER_TEMAS: {
    label: i18n.t('funcionalidade.temas'),
    icon: 'cil-tags',
    path: '/temas',
    group: i18n.t('grupo.administracao')
  } as MenuItem,
  MANTER_AGENDA: {
    label: i18n.t('funcionalidade.agenda'),
    icon: 'cil-calendar',
    path: '/profissional/agenda',
    group: i18n.t('grupo.principal')
  } as MenuItem,
  BUSCAR_PROFISSIONAL: {
    label: i18n.t('funcionalidade.buscarProfissional'),
    icon: 'cil-search',
    path: '/profissional/buscar',
    group: i18n.t('grupo.principal')
  },
  LISTAR_ATENDIMENTO: {
    label: i18n.t('label.minhasConsultorias'),
    icon: 'cil-list',
    path: '/atendimento/listar',
    group: i18n.t('grupo.principal')
  },
  MANTER_FAQ: {
    label: i18n.t('label.FAQ'),
    icon: 'cil-list',
    path: '/Faq/listarFAQ',
    group: i18n.t('grupo.administracao')
  },
  RELATORIOS_CONSULTORIA: {
    label: i18n.t('label.relatorios.consultorias'),
    icon: 'cil-list',
    path: '/Relatorios/consultorias',
    group: i18n.t('grupo.relatorios')
  },
  LISTAR_LEMBRETES: {
    label: i18n.t('label.lembretes'),
    icon: 'cil-bullhorn',
    path: '/Lembretes/lembretes',
    group: i18n.t('grupo.administracao')
  }
}

if (InstituicaoHelper.isMentoria()) {
  Object.assign(menuItemPorFuncionalidade, {
    RELATORIOS_CONSULTORIA: {
      label: 'Mentorias',
      icon: 'cil-list',
      path: '/Relatorios/consultorias',
      group: i18n.t('grupo.relatorios')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    BUSCAR_PROFISSIONAL: {
      label: 'Agendar Mentoria',
      icon: 'cil-search',
      path: '/profissional/buscar',
      group: i18n.t('grupo.principal')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    LISTAR_ATENDIMENTO: {
      label: 'Minhas Mentorias',
      icon: 'cil-list',
      path: '/atendimento/listar',
      group: i18n.t('grupo.principal')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    EDITAR_PROFISSIONAL: {
      label: i18n.t('funcionalidade.visualizarProfissional'),
      icon: 'cil-list',
      path: '/profissional/visualizar',
      group: i18n.t('grupo.principal')
    }
  })
}

/* Controla o menu caso seja o CNDL */
if (
  InstituicaoHelper.isCNDL() ||
  InstituicaoHelper.isBinMinas() ||
  InstituicaoHelper.isMentoria() ||
  InstituicaoHelper.isSebraeGO()
) {
  const listarClientes = {
    LISTAR_CLIENTES: {
      label: InstituicaoHelper.isBinMinas()
        ? i18n.t('label.startup')
        : i18n.t('label.clientes'),
      icon: 'cil-group',
      path: '/Clientes/ListarClientes',
      group: i18n.t('grupo.administracao'),
      order: 0
    }
  }
  Object.assign(menuItemPorFuncionalidade, listarClientes)

  Object.assign(menuItemPorFuncionalidade, {
    MANTER_PROFISSIONAL: {
      // eslint-disable-next-line no-nested-ternary
      label: InstituicaoHelper.isBinMinas()
        ? i18n.t('label.cvb')
        : InstituicaoHelper.isMentoria()
          ? 'Mentores'
          : i18n.t('label.profissionais'),
      icon: 'cil-group',
      path: '/profissionais/manter/listar',
      group: i18n.t('grupo.administracao'),
      order: 1
    } as MenuItem
  })
}

if (InstituicaoHelper.isSebraeMG()) {
  Object.assign(menuItemPorFuncionalidade, {
    MANTER_PROFISSIONAL: {
      label: i18n.t('label.profissionais'),
      icon: 'cil-group',
      path: '/profissionais/manter/listar',
      group: i18n.t('grupo.administracao')
    } as MenuItem
  })

  Object.assign(menuItemPorFuncionalidade, {
    DASHBOARD: {
      label: i18n.t('label.dashboard'),
      icon: 'cil-bullhorn',
      path: '/Dashboard/dashboard',
      group: i18n.t('grupo.administracao')
    }
  })

  Object.assign(menuItemPorFuncionalidade, {
    EVENTOS: {
      label: i18n.t('label.eventos'),
      icon: 'cil-bullhorn',
      path: '/Eventos/eventos',
      group: i18n.t('grupo.eventos')
    }
  })

  Object.assign(menuItemPorFuncionalidade, {
    EDITAR_PROFISSIONAL: {
      label: i18n.t('funcionalidade.visualizarProfissional'),
      icon: 'cil-list',
      path: '/profissional/visualizar',
      group: i18n.t('grupo.principal')
    }
  })
}

if (InstituicaoHelper.isSmartBoard()) {
  Object.assign(menuItemPorFuncionalidade, {
    MANTER_PROFISSIONAL: {
      label: 'Conselheiros',
      icon: 'cil-group',
      path: '/profissionais/manter/listar',
      group: i18n.t('grupo.administracao')
    } as MenuItem
  })
  Object.assign(menuItemPorFuncionalidade, {
    DATA_ROOM: {
      label: 'Data Room',
      icon: 'cil-folder-open',
      path: '/profissionais/dataroom',
      group: i18n.t('grupo.principal')
    } as MenuItem
  })
  Object.assign(menuItemPorFuncionalidade, {
    ASSINATURA: {
      label: 'Minha Assinatura',
      icon: 'cil-badge',
      path: '/profissionais/subscription',
      group: i18n.t('grupo.principal')
    } as MenuItem
  })
  Object.assign(menuItemPorFuncionalidade, {
    FEEDBACK: {
      label: 'Feedback',
      icon: 'cil-comment-bubble',
      path: '/feedback',
      group: i18n.t('grupo.suporte')
    } as MenuItem
  })
  Object.assign(menuItemPorFuncionalidade, {
    TOOL_KIT: {
      label: 'Tool Kit Lean Governance',
      icon: 'cil-briefcase',
      path: '/profissionais/toolkit',
      group: i18n.t('grupo.leanGovernance')
    } as MenuItem
  })

  Object.assign(menuItemPorFuncionalidade, {
    FORM_PAGE: {
      label: 'Autodiagnóstico',
      icon: 'cil-list-rich',
      path: '/profissionais/autodiagnostico',
      group: i18n.t('grupo.leanGovernance')
    } as MenuItem
  })
  Object.assign(menuItemPorFuncionalidade, {
    ATAS: {
      label: 'Atas de Reunião',
      icon: 'cil-description',
      path: '/profissionais/atas',
      group: i18n.t('grupo.principal')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    EDITAR_PROFISSIONAL: {
      label: i18n.t('funcionalidade.visualizarProfissional'),
      icon: 'cil-user',
      path: '/profissional/visualizar',
      group: i18n.t('grupo.principal')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    BUSCAR_PROFISSIONAL: {
      label: 'Buscar Conselheiro',
      icon: 'cil-search',
      path: '/profissional/buscar',
      group: i18n.t('grupo.principal')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    LISTAR_ATENDIMENTO: {
      label: 'Minhas Reuniões',
      icon: 'cil-list',
      path: '/atendimento/listar',
      group: i18n.t('grupo.principal')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    AGENDAR_REUNIAO: {
      label: 'Agendar Reunião',
      icon: 'cil-calendar',
      path: '/atendimento/agendarReuniao',
      group: i18n.t('grupo.principal')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    RELATORIOS_CONSULTORIA: {
      label: 'Conselhos',
      icon: 'cil-list',
      path: '/Relatorios/consultorias',
      group: i18n.t('grupo.relatorios')
    }
  })
  Object.assign(menuItemPorFuncionalidade, {
    LISTAR_CLIENTES: {
      label: i18n.t('label.clientes'),
      icon: 'cil-group',
      path: '/Clientes/ListarClientes',
      group: i18n.t('grupo.administracao')
    }
  })
}

if (true) {
  Object.assign(menuItemPorFuncionalidade, {
    AGENDA: {
      label: 'Agenda',
      icon: 'cil-calendar',
      path: '/Agenda/AgendaPrincipal',
      group: i18n.t('grupo.administracao')
    }
  })
}
/* *************************************************************************** */

const gerarMenu = (funcionalidades: string[]): any[] => {
  const menuComponents = [] as any[]
  const groups = new Set<string>()

  // Ordem específica para SmartBoard
  const orderedGroups = ['Principal', 'Lean Governance', 'Suporte']

  funcionalidades
    .map(funcionalidade => menuItemPorFuncionalidade[funcionalidade])
    .filter((item): item is MenuItem => !!item) // Filtra itens válidos
    .sort((item: MenuItem, other: MenuItem): number => {
      if (InstituicaoHelper.isSmartBoard()) {
     
        // Ordenação personalizada para SmartBoard
        const itemGroupIndex = orderedGroups.indexOf(item.group)
        const otherGroupIndex = orderedGroups.indexOf(other.group)

        if (itemGroupIndex !== -1 && otherGroupIndex !== -1) {
          return itemGroupIndex - otherGroupIndex // Ordem baseada na lista
        }

        // Grupos não listados vêm depois
        if (itemGroupIndex !== -1) return -1
        if (otherGroupIndex !== -1) return 1

        // Fallback para ordenação alfabética
        return item.group.localeCompare(other.group)
      } else{
        // Ordenação padrão para outras instituições
        if (item.group > other.group) return 1
        if (item.group < other.group) return -1

        // Ordenação por rótulo dentro do mesmo grupo
        return item.label.localeCompare(other.label)
      }
      
    })
    .forEach((menuItem: MenuItem) => {
      if (!groups.has(menuItem.group)) {
        menuComponents.push({
          _tag: 'CSidebarNavTitle',
          _children: [menuItem.group || 'Grupo Desconhecido'] // Tratamento para grupos indefinidos
        })
        groups.add(menuItem.group)
      }

      menuComponents.push({
        _tag: 'CSidebarNavItem',
        name: menuItem.label,
        to: menuItem.path,
        icon: menuItem.icon
      })
    })

  return menuComponents
}


export default gerarMenu
